<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>Uczestnicy Programu</b>.
      </div>
    </b-alert>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview
          title="Lista uczestników"
          v-if="state === 'loaded'"
        >
          <template v-slot:preview>
            <div>
              <b-row>
                <b-col></b-col>
                <b-col lg="6" class="my-1">
                  <b-form-group
                    label="Filtuj"
                    label-for="filter-input"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Szukaj..."
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"
                          >Wyczyść</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                responsive
                striped
                hover
                :items="users.items"
                :fields="users.fields"
                :filter="filter"              
              >
                <template #cell(actions)="row">
                  <b-button-group>
                    <b-button
                      variant="primary"
                      size="sm"
                      class="mb-1"
                      @click="getTransactionHistory(row.item)"
                    >
                      <b-icon icon="list-ul" aria-hidden="true"></b-icon>
                    </b-button>
                    
                    <b-button
                      variant="warning"
                      size="sm"
                      class="mb-1"
                      @click="getPoints(row.item)"
                    >
                      <b-icon icon="cash" aria-hidden="true"></b-icon>
                    </b-button>  
                  </b-button-group>
                </template>
              </b-table>
            </div>
          </template>
        </KTCodePreview>
        <div class="d-flex justify-content-center mb-3" v-else>
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>
    </div>

    <template>
      <!-- details modal  -->
      <b-modal
        size="xl"
        scrollable
        ref="transactionHistoryModal"
        :title="acronym"
      >
        <div class="d-block text-center"></div>
        <b-table
          responsive
          striped
          bordered
          hover
          :fields="transactionHistory.fields"
          :items="transactionHistory.items"
        >
        </b-table>
        <div class="d-flex justify-content-center mb-3" v-if="modalState === 'loading'">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </b-modal>
      <!-- end of details modal -->
    </template>

  </div>
</template>
<style>
.action-column {
  width: 50px;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import KTCodePreview from "@/view/content/CodePreview";
import Swal from "sweetalert2";

export default {
  name: "Users",
  data() {
    return {
      state: "loading",
      modalState: "loading",
      acronym: "",
      points: 0,
      users: {
        fields: [
          {
            key: "acronym",
            label: "Akronim",
            sortable: true
          },
          {
            key: "loyalityCard",
            label: "Nr karty",
            sortable: true
          },
          {
            key: "person",
            label: "Uczestnik",
            sortable: true
          },
          {
            key: "phone",
            label: "Telefon",
            sortable: true
          },
          {
            key: "email",
            label: "E-mail",
            sortable: true
          },
          {
            key: "agreementDateFrom",
            label: "Data Przystąpienia",
            sortable: true
          },
          {
            key: "documentDateFrom",
            label: "Transakcje Od",
            sortable: true
          },          
          {
            key: "actions",
            label: "Akcje",
            thClass: "action-column"
          }
        ],
        items: []
      },
      transactionHistory: {
        fields: [
          {
            key: "documentFullNo",
            label: "Nr Dokumentu",
            sortable: true
          },
          {
            key: "sellDate",
            label: "Data",
            sortable: true
          },
          {
            key: "dueDate",
            label: "Termin",
            sortable: true
          },
          {
            key: "payment",
            label: "Wartość",
            sortable: true,
            formatter: value => {
              return this.numberFormat(value);
            }
          },
          {
            key: "paid",
            label: "Zapłacono",
            sortable: true,
            formatter: value => {
              return this.numberFormat(value);
            }
          },
          {
            key: "paymentLeft",
            label: "Pozostaje do zapłaty",
            sortable: true,
            formatter: value => {
              return this.numberFormat(value);
            }
          },
          {
            key: "paymentStatus",
            label: "Status Płatności",
            sortable: true
          },
          {
            key: "pointsBySku",
            label: "Punkty",
            sortable: true,
            formatter: value => {
              return this.numberFormat(value);
            }
          }
        ],
        items: []
      },      
      filter: null
    };
  },
  components: {
    KTCodePreview
  },
  methods: {
    getUsers() {
      ApiService.get("/users").then(response => {
        this.users.items = response.data;
        this.state = "loaded";
      });
    },
    getTransactionHistory(row) {
      this.transactionHistory.items = [];
      this.modalState = "loading";
      this.acronym = row.acronym;
      this.$refs.transactionHistoryModal.show();
      ApiService.get("/transaction-history/"+row.id).then(response => {        
        this.transactionHistory.items = response.data;
        this.modalState = "loaded";
      });
    },
    getPoints(row) {
      Swal.fire({
        title: "proszę czekać...",
        onBeforeOpen(){Swal.showLoading()}
      })
      ApiService.get("/overal/points/"+row.id).then(result => {
        this.points = result.data.gainedPoints;
        Swal.fire({
          title: "Saldo punktów",
          text: this.points,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#009136",
          confirmButtonText: "Zamknij"
        })
      });
    },
    numberFormat(value) {
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Administrator" },
      { title: "Lista uczestników" },
      { title: "" }
    ]);
    this.getUsers();
  }
};
</script>
